<style lang="less" scoped>
	@import "../../../assets/css/variables";

  .page-container {
    color: #666666;
    font-size: 12px;
    line-height: 18px;
  }

  ul {
    padding: 0;
    li {
       list-style-type: none;
       padding:0;
       font-size: 14px;
       margin-bottom: 10px;
       width:100%;
       display: flex;
       .f-lt {
         flex: 0 0 100px;
         text-align: right;
       }
       .f-rt {
         flex: 1 1 auto;
         overflow: hidden;
         margin-left: 15px;
       }
     }
  }
  .title {
    display: inline-block;
    line-height: 30px;
    width:100px;
    text-align-last: right;
  }
  .content {
    margin-left: 15px;
  }
  .img img {
    vertical-align: middle;
    display: inline-block;
    width:120px;
    margin-left: 15px;
    /*height: 50px;*/
  }

  .productImg{
    width: 55px;
    line-height: 55px;
    height: 55px;
    border: 1px solid #efefef;
    margin: 10px auto;
    text-align: center;
    color: #999;
    .img{
      width: 50px;
      height: 50px;
      margin: 2px auto;
      color: #bbb;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }

</style>

<template :is="view"
          transition="fade"
          transition-mode="out-in">
  <div class="page-wrapper">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/device/pkind' }">品牌列表</el-breadcrumb-item>
      <el-breadcrumb-item >品牌详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-container">
      <el-row>
        <el-col :span="12">
          <ul class="brand-info">
            <li>
              <span class="f-lt">APP ID：</span>
              <span class="f-rt">{{productInfo.appId}}</span>
            </li>
            <li>
              <span class="f-lt">系列名称：</span>
              <span class="f-rt">{{productInfo.productName ? productInfo.productName : '--' }}</span>
            </li>
            <li>
              <span class="f-lt">产品型号：</span>
              <span class="f-rt">{{productInfo.deviceTypeCode+productInfo.productTypeCode}}</span>
            </li>
            <li>
              <span class="f-lt">品牌型号：</span>
              <span class="f-rt">{{productInfo.belongsType+productInfo.belongsSeries}}</span>
            </li>
            <li v-if="productInfo.surpluslink1">
              <span class="f-lt">滤芯地址1：</span>
              <span class="f-rt">{{productInfo.surpluslink1}}</span>
            </li>
            <li v-if="productInfo.surpluslink2">
              <span class="f-lt">滤芯地址2：</span>
              <span class="f-rt">{{productInfo.surpluslink2}}</span>
            </li>
            <li v-if="productInfo.surpluslink3">
              <span class="f-lt">滤芯地址3：</span>
              <span class="f-rt">{{productInfo.surpluslink3}}</span>
            </li>
            <li v-if="productInfo.surpluslink4">
              <span class="f-lt">滤芯地址4：</span>
              <span class="f-rt">{{productInfo.surpluslink4}}</span>
            </li>
            <li v-if="productInfo.connectUrl">
              <span class="f-lt">产品链接：</span>
              <span class="f-rt">{{productInfo.connectUrl}}</span>
            </li>
            <li>
              <span class="f-lt">productid：</span>
              <span class="f-rt">{{productInfo.productId}}</span>
            </li>
            <li>
              <span class="f-lt">设备描述：</span>
              <span class="f-rt">{{productInfo.remarks}}</span>
            </li>
          </ul>
        </el-col>
        <el-col :span="12">
          <el-row>
            <div>
              <span class="title">产品图片：</span>
              <span class="img" v-if="productInfo.imageUrl">
                <img :src="API_ROOT+productInfo.imageUrl">
              </span>
            </div>
          </el-row>
          <el-row style="margin-top:60px;">
            <div>
              <span class="title">类型二维码：</span>
              <span class="img" v-if="productInfo.qrticket">
                <img :src="API_ROOT+productInfo.qrticket">
              </span>
            </div>
          </el-row>
        </el-col>
      </el-row>
      <el-row style="margin-top:10px;" v-if="productInfo.list">
        <el-table
          :data="productInfo.list"
          style="width: 100%">
          <el-table-column
            prop="dataStreamName"
            label="模式类型"
            align="center"
            :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column
            prop="aliasName"
            label="模式名称"
            align="center"
            :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column
            prop="selectImageUrl"
            label="打开图标"
            align="center"
            :show-overflow-tooltip="true">
              <template slot-scope="scope">
                <div v-if="scope.row.selectImageUrl" class="productImg">
                    <div class="img" v-lazy:background-image="API_ROOT+scope.row.selectImageUrl"></div>
                </div>
                <div v-if="!scope.row.selectImageUrl" class="productImg">暂无图片</div>
              </template>
          </el-table-column>
          <el-table-column
            prop="unselectImageUrl"
            align="center"
            label="关闭图标"
            :show-overflow-tooltip="true">
              <template slot-scope="scope">
                <div v-if="scope.row.unselectImageUrl" class="productImg">
                    <div class="img" v-lazy:background-image="API_ROOT+scope.row.unselectImageUrl"></div>
                </div>
                <div v-if="!scope.row.unselectImageUrl" class="productImg">暂无图片</div>
              </template>
          </el-table-column>
        </el-table>
      </el-row>
    </div>
  </div>
</template>

<script>
  import {getprodTypeById} from '../../../services/device';
	import {API_ROOT} from '../../../config';
	export default{
    data() {
      return {
        productInfo: {},
	API_ROOT
      }
    },
    methods: {
      /**
       * 获取产品型号byid
       */
      async getProductInfo(id) {
        const res = await getprodTypeById(id);
        if (res && res.errorCode === 0 && res.data) {
          this.productInfo = res.data;
          console.log(this.productInfo)
        }
      }
    },
    created() {
      const id = this.$route.params.id || 0;
      if (id) {
        this.getProductInfo(id);
      }
    }
  }
</script>
